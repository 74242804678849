const isVisibleMap = new Map();

export function setupConditionalRendering() {
    $("body").on("change", render).on("click", render).on("input", render);
    render();
    setupRenderingInSteps();
}

function setupRenderingInSteps() {
    function validateSection() {
        if (getCurrentStep() === 0) {
            if ($("#service_type-cleaning")[0].checked) {
                const $multiplier = $("#multiplier");
                if (!$multiplier.val()) {
                    $multiplier.closest(".booking__opportunity").addClass("error");
                    return false;
                }
            }
        }
        return true;
    }
    function showNextSection() {
        setCurrentStep(getCurrentStep() + 1);
    }

    $(".next-service").on("click", () => {
        if (validateSection()) {
            showNextSection();
        }
    });
}

export function render() {
    for (const elem of $("*[data-show-on]")) {
        const condition = $(elem).attr("data-show-on");
        const isVisible = condition
            .split("|")
            .some((token) =>
                token
                    .split("&")
                    .every((elem) =>
                        elem.startsWith("!") ? !document.querySelector(elem.slice(1)) : !!document.querySelector(elem)
                    )
            );
        if (isVisibleMap.get(elem) !== isVisible) {
            changeVisibility($(elem), isVisible);
        }
        isVisibleMap.set(elem, isVisible);
    }
}

function deselectChildren(elems) {
    for (const child of elems) {
        if ($(child).attr("data-deselect-on-invisible")) {
            if (isChecked(child)) {
                child.click();
            }
        }
        deselectChildren($(child).children());
    }
}

function isChecked(target) {
    return target.checked || $(target).hasClass("selected-input");
}

function changeVisibility(elem, isVisible) {
    if (isVisible) {
        elem.fadeIn(300);
        elem.addClass("show");
    } else {
        elem.fadeOut(300);
        elem.removeClass("show");
        elem.hide();
        deselectChildren([elem]);
    }
    const autoSelectQuery = $(elem).attr("data-auto-select-on-visible");
    if (autoSelectQuery) {
        const target = $(autoSelectQuery).get(0);
        if (target) {
            setTimeout(() => {
                if ((isVisible && !isChecked(target)) || (!isVisible && isChecked(target))) target.click();
            }, 250);
        }
    }
}

export function getCurrentStep() {
    return Math.max(
        0,
        ...[...(getStepManager()?.classList || [])].map((className) => Number(className.match(/step-(\d+)/)?.[1] || 0))
    );
}

export function setCurrentStep(step) {
    function generateStepClasses(step) {
        return Array(step)
            .fill(0)
            .map((elem, index) => `step-${index + 1}`);
    }
    getStepManager().className = ["step-manager", ...generateStepClasses(step)].join(" ");
}

function getStepManager() {
    return $(".step-manager").get(0);
}

export function calculateLargestStepUnderElement(element) {
    const elementsWithCandidateClassnames = [...$(element).find("*[class*='step-']")];
    const presentStepClassNames = elementsWithCandidateClassnames
        .map((elem) => [...elem.classList.values()].filter((elem) => elem.startsWith("step-")))
        .flat(2);
    const stepNumbers = presentStepClassNames.map((elem) => Number(elem.match(/step-(\d)/)?.[1]) || 0);
    return Math.max(...stepNumbers);
}
