import { calculateLargestStepUnderElement } from "./conditional-rendering.js";

/** When a booking is made by choosing a bundle, we need to bring all service types in the bundle into a single
 *  flow. This takes care of that. */
export function setupMultipartForm() {
    if (getActiveServices().length > 1) {
        bringFormsIntoASingleFlow();
    } else {
        resetNextAndSubmitButtons(getCleaningForm());
    }
}

function bringFormsIntoASingleFlow() {
    const cleaningForm = getCleaningForm();
    if (!cleaningForm) return;
    const activeServices = getActiveServices().filter((elem) => elem !== "cleaning");

    for (const service of activeServices) {
        const serviceForm = $("#" + service).get(0);
        const largestStep = calculateLargestStepUnderElement(cleaningForm);
        if (serviceForm) {
            cleaningForm.appendChild(serviceForm);
            serviceForm.classList.add("step-" + (largestStep + 1));
        }
    }

    resetNextAndSubmitButtons(cleaningForm);

    $(".booking__service").on("click", () => {
        scatterFormsBackIntoMultipleFlows();
        resetNextAndSubmitButtons(cleaningForm);
    });
}

function resetNextAndSubmitButtons(form) {
    const nextButton = $(form).find("button.next-service").get(0);
    if (nextButton) {
        form.appendChild(nextButton);
        nextButton.setAttribute("data-show-on", "!.step-manager.step-" + calculateLargestStepUnderElement(form));
    }

    const submitButton = $(form).find("button[type='submit']").get(0);
    if (submitButton) {
        form.appendChild(submitButton);
        submitButton.setAttribute("data-show-on", ".step-manager.step-" + calculateLargestStepUnderElement(form));
    }
}

function scatterFormsBackIntoMultipleFlows() {
    const cleaningForm = getCleaningForm();
    const otherForms = [...$(cleaningForm).find(".booking__service__content")];
    for (const form of otherForms) {
        if (form.id) {
            const newParent = $(`*[data-service='${form.id}'].booking__service__content__wrap`).get(0);
            newParent.prepend(form);
            for (const i of Array(10)
                .fill(0)
                .map((elem, index) => index)) {
                form.classList.remove("step-" + i);
            }
        }
    }
}

function getCleaningForm() {
    return $(".booking__service__content__wrap[data-service='cleaning']").get(0);
}

function getActiveServices() {
    return [
        ...new Set(
            backendVars.summaryData.finalItems
                .filter((elem) => elem.type === "primary" && !!elem.section)
                .map((elem) => elem.section)
        ).values(),
        // We need to keep the cleaning form right where it is.
    ];
}
