const $ = jQuery;

export function calculateRangeSlider($element) {
    var rangeVal = ($element.val() - $element.attr("min")) / ($element.attr("max") - $element.attr("min"));
    var rangePercent = rangeVal * 100;
    rangeSlider($element, rangePercent);
}

function rangeSlider(input, percent) {
    input.css(
        "background-image",
        "-webkit-gradient(linear, left top, right top, " +
            "color-stop(" +
            percent +
            "%, #1B88F5), " +
            "color-stop(" +
            percent +
            "%, #E6EBF2)" +
            ")"
    );

    input.css(
        "background-image",
        "-moz-linear-gradient(left center, #1B88F5 0%, #1B88F5 " +
            percent +
            "%, #E6EBF2 " +
            percent +
            "%, #F5D0CC 100%)"
    );
}
