import { addCssRule, isMovingOutSelected } from "./utils.js";

const $ = jQuery;
const hideNormalPricesClass = "hide-normal-prices";
const hideMovingOutPricesClass = "hide-moving-out-prices";

export function setupServiceCards() {
    addServiceCardClickListener();
    setupServiceCardDropdowns();
    handleSelectAndDeselectOfCustomizableServiceCard();
    refreshServiceCardPrices(backendVars?.summaryData?.items?.find?.((elem) => elem.name === "multiplier")?.value);
    addServiceCardStyleRules();
}

function setupServiceCardDropdowns() {
    const $select2ListItems = $(".list-item-select2");

    $select2ListItems.each(function (key, val) {
        const dropdownParent = $(val).closest(".booking__content__list__item.list-item-variable");
        $(val).select2({
            minimumResultsForSearch: -1,
            dropdownParent,
            escapeMarkup: function (markup) {
                return markup;
            },
        });
    });
}

/** Changes service card prices based on whether "Moving out" option is selected  */
export function refreshServiceCardPrices(isMovingOut) {
    document.body.classList.add(isMovingOut ? hideNormalPricesClass : hideMovingOutPricesClass);
    document.body.classList.remove(isMovingOut ? hideMovingOutPricesClass : hideNormalPricesClass);
}

function handleSelectAndDeselectOfCustomizableServiceCard() {
    $(document).on("change", ".booking__content__list__item.list-item-variable select", function () {
        var $selectedVariable = $(this).find("option:selected");
        var $parentElement = $selectedVariable.closest(".booking__content__list__item");
        var variableValue = $selectedVariable.val();

        if (variableValue > 0) {
            $parentElement.addClass("selected-input");
            $parentElement.data("input", "cleaning-" + variableValue);
        } else {
            $parentElement.removeClass("selected-input");
        }
    });
}

function addServiceCardClickListener() {
    $(document).on("click", ".booking__content__list__item", function (e) {
        if ($(e.target).closest(".tooltip").length) {
            return false;
        }

        const $this = $(this);
        const dataInput = $this.attr("data-input");
        let $connectedAction;
        if (!!dataInput) {
            $connectedAction = $("#" + dataInput);
        }

        if ($this.hasClass("list-item-variable")) {
            $connectedAction = $this.find("select");
        }

        const clickDidComeFromUser = e.originalEvent.isTrusted;

        if ($this.hasClass("selected-input")) {
            if (clickDidComeFromUser) {
                $this.attr("data-manually-checked", false);
            }
            if ($connectedAction.prop("nodeName") === "INPUT") {
                $this.removeClass("selected-input");
                $connectedAction.prop("checked", false);
            } else if (e.target.getAttribute("class") !== "select2-selection__rendered") {
                $connectedAction.prop("selectedIndex", 0);
            }
        } else {
            if (clickDidComeFromUser) {
                $this.attr("data-manually-checked", true);
            }
            if ($connectedAction.prop("nodeName") === "INPUT") {
                $this.addClass("selected-input");
                $connectedAction.prop("checked", true);
            } else {
                $connectedAction.val($connectedAction.find("option").eq(1).val());
            }
        }

        $connectedAction.trigger("change");
    });
}

function addServiceCardStyleRules() {
    addCssRule(`
        .${hideMovingOutPricesClass} span[data-price-for=moving-out] {
            display: none;
        }
        .${hideNormalPricesClass} span[data-price-for=normal] {
            display: none;
        }
    `);
}
