import { createCustomer } from "./utils.js";

const $ = jQuery;

/** Refreshes the paypal button with the correct payment data */
export function refreshPaypalButton() {}

function getPaypalData() {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "get",
            url: globalVars.ajaxPaths.getPaypalData, // The globalVars.ajaxPaths object comes from backend through ctp template
            dataType: "json",
        })
            .done(function (paypalData) {
                resolve(paypalData);
            })
            .fail(() => {
                reject();
            });
    });
}

// url: globalVars.ajaxPaths.getPaypalData
async function renderPaypalButton(paypal) {
    if (!paypal) return;
    const paypalData = await getPaypalData();
    return paypal
        .Buttons({
            style: {
                color: "white",
            },
            createOrder: function (data, actions) {
                return createCustomer("paypal").then(() => {
                    // Set up the transaction
                    const orderData = {
                        purchase_units: [
                            {
                                reference_id: paypalData.cleaningHash,
                                invoice_id: paypalData.cleaningHash,
                                amount: {
                                    currency_code: paypalData.currency,
                                    value: paypalData.total,
                                    breakdown: {
                                        item_total: {
                                            currency_code: paypalData.currency,
                                            value: roundToPrecision(paypalData.item_total, 2),
                                        },
                                        tax_total: {
                                            currency_code: paypalData.currency,
                                            value: roundToPrecision(paypalData.vat, 2),
                                        },
                                        discount: {
                                            currency_code: paypalData.currency,
                                            value: roundToPrecision(paypalData.discount, 2),
                                        },
                                    },
                                },
                                items: paypalData.items,
                            },
                        ],
                    };
                    return actions.order.create(orderData);
                });
            },
            onApprove: function (data, actions) {
                return actions.order.capture().then(function (details) {
                    var urlBase = location.protocol + "//" + window.location.hostname + "/cleanings/";
                    var invoiceId = paypalData.cleaningHash;

                    if (details.status === "COMPLETED") {
                        var successUrl = urlBase + "success/" + paypalData.cleaningHash;
                        window.location.replace(successUrl);
                    } else {
                        var errorUrl = urlBase + "error/" + paypalData.cleaningHash;
                        window.location.replace(errorUrl);
                    }
                });
            },
            onCancel: function () {
                var urlBase = location.protocol + "//" + window.location.hostname + "/cleanings/";
                var errorUrl = urlBase + "error/" + paypalData.cleaningHash;
                window.location.replace(errorUrl);
            },
        })
        .render("#paypal-button-container");
}

function roundToPrecision(number, precision) {
    return Math.round(number * 10 ** precision) / 10 ** precision;
}
