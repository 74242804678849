import { refreshSummaryRequest } from "./summary.js";

export function handleValidations() {
    for (const serviceType of Object.keys(validators)) {
        function runValidation(serviceType) {
            setTimeout(() => {
                const isFrequencySelected =
                    !!$(`input[name=${serviceType}-frequency]:checked`).length || serviceType === "gardening";
                const submitButton = $(
                    `*[data-service=${serviceType}].booking__service__content__wrap button[type=submit]`
                );
                submitButton.prop("disabled", false);
                submitButton.attr("data-reason", "");
                const isServiceTypeActive = !!$(
                    `#${serviceType} input[name^=${serviceType}-]:checked:not([name^=${serviceType}-frequency])`
                ).length;
                let isValidatorValid;
                try {
                    isValidatorValid = isServiceTypeActive ? validators[serviceType]() : false;
                } catch (e) {
                    isValidatorValid = false;
                }
                const isValid = isFrequencySelected && isServiceTypeActive && isValidatorValid;

                // Handle required fields
                for (const elem of $(`#${serviceType} *:is(input,textarea)[data-required]`)) {
                    if (!elem.value && !!document.querySelector($(elem).attr("data-show-on"))) {
                        console.log({ elem });
                        submitButton.attr("data-reason", $(elem).attr("data-error-message"));
                        submitButton.prop("disabled", true);
                        return false;
                    }
                }

                // Handle disclaimers
                for (const elem of $(`#${serviceType} input[name$='-consent'][id]`)) {
                    const serviceName = elem.name.match(/(.+)-consent/)[1];
                    const serviceChecked = !!$(`input[name=${serviceName}]:checked`).length;
                    if (serviceChecked && !elem.checked) {
                        submitButton.attr("data-reason", "Please check the consent checkbox!");
                        submitButton.prop("disabled", true);
                        return false;
                    }
                }
                submitButton.prop("disabled", !isValid);
            }, 50);
        }
        $("body").on("click", "#" + serviceType, () => {
            console.log("running validation...");
            runValidation(serviceType);
        });
        $(`#${serviceType} input, #${serviceType} textarea`).on("change", () => {
            runValidation(serviceType);
            refreshSummaryRequest();
        });
        runValidation(serviceType);
    }

    $("button[type=submit]").on("pointerdown", (e) => {
        const button = e.target;
        if ($(button).prop("disabled") && button.getAttribute("data-reason")) {
            Toastify({
                text: button.getAttribute("data-reason"),
                duration: 3000,
            }).showToast();
        }
    });
}

const validators = {
    "car-washing": () => {
        return !!$("input[name^=car-washing][type=radio]:checked:not([name^=car-washing-frequency])").length;
    },
    "pet-care": () => {
        return true;
    },
    organizing: () => true,
    painting: () => {
        return !!$("input[name^=painting]:checked:not([type=radio])").length;
    },
    gardening: () => {
        const fileSize = [...$("#photos").get(0).files]
            .map((file) => file.size / 1024 / 1024)
            .reduce((prev, curr) => prev + curr, 0);
        const arefilesOk = fileSize < 5;
        if (!arefilesOk) {
            Toastify({
                text: "The maximum size of all files together must not exceed 5MB",
                duration: 3000,
            }).showToast();
        }
        return arefilesOk;
    },
};
