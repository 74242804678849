import { refreshPaypalButton } from "./paypal.js";
import {
    addCssRule,
    getBookingOverviewItems,
    getFinalizeButton,
    getSummaryLoader,
    refreshInputs,
    setBookingHours,
} from "./utils.js";

const $ = jQuery;

export let firstRun = true;
let serviceChangeTimeout;

export function setupSummaryWindow() {
    // Handle the summary window open and close state
    if ($(".booking__overview").length > 0) {
        var closeBtn = $(".booking__summary__close");
        var overviewHead = $(".booking__overview__header");

        overviewHead.on("click", function (e) {
            e.preventDefault();
            overviewHead.hide();
            $(".booking__summary").fadeIn(200);
        });

        closeBtn.on("click", function (e) {
            e.preventDefault();
            $(".booking__summary").hide(0);
            overviewHead.fadeIn(200);
        });
    }
    if (backendVars?.summaryData) {
        refreshSummary(backendVars.summaryData);
    }
    addCssRule(`
        .completely-hidden {
            display: none !important;
        }
    `);
}

let currentRequestController = null;

export function refreshSummaryRequest($input, items, couponCode) {
    if ($input) {
        refreshInputs($input);
    }

    getSummaryLoader().fadeIn(150);
    getFinalizeButton().prop("disabled", true);

    // Throttle the change requests to not flood the server unnecessarily
    if (serviceChangeTimeout) {
        clearTimeout(serviceChangeTimeout);
    }

    serviceChangeTimeout = setTimeout(() => sendRequest(items, couponCode), firstRun ? 0 : 1000);

    function sendRequest(items, couponCode) {
        // Cancel the previous request if it's still ongoing
        if (currentRequestController) {
            currentRequestController.abort();
        }

        let itemData = items ? items : getSerializedData();
        if (!itemData || itemData.length === 0) return;
        window.summaryAtom.focus((atom) => atom.loading).update(() => true);
        currentRequestController = $.ajax({
            type: "post",
            url: globalVars.ajaxPaths.refreshSummary,
            dataType: "json",
            data: {
                items: items ? items : getSerializedData(),
                coupon: couponCode,
            },
        });
        currentRequestController.done(refreshLocalStateWithFreshSummary).always(() => {
            currentRequestController = null;
        });
    }
}

export function refreshLocalStateWithFreshSummary(response) {
    window.backendVars.summaryData = response;
    window.summaryAtom.update(() => ({ loading: false, finalItems: response.finalItems }));
    console.log("updating loading to false");

    setBookingHours(response);
    refreshSummary(response);
    handleCoupon(response);
    // Click to refresh conditional rendering
    setTimeout(() => {
        document.body.click();
    }, 500);
    firstRun = false;
}

export function refreshSummary(serverResponse) {
    getFinalizeButton().prop("disabled", false);
    const total = serverResponse.finalItems.find(
        (elem) => elem.name === "Total" && elem.section === null,
    ).price;
    const priceText = document.querySelector(".price_text");
    if (priceText) {
        priceText.innerHTML = total;
    }
    const itemsItem = document.querySelector(".items");
    if (itemsItem) {
        if (Number(total) === 0) {
            itemsItem.style.display = "inline";
        } else {
            itemsItem.style.display = "none";
        }
    }
}

function handleCoupon(dataSet) {
    if (!dataSet) {
        return;
    }

    $.each(dataSet, function (key, val) {
        if (["discount", "frequency_discount"].includes(val.field_name)) {
            // RENDERING HTML CHUNKS FROM AJAX
            refreshBorgunForm();
            $("#paypal-button-container").html("");
            refreshPaypalButton();
        }
    });
}

function refreshBorgunForm() {
    $.ajax({
        type: "post",
        url: globalVars.ajaxPaths.getPaymentElement,
    }).done(function (response) {
        if (response) {
            $("#borgun-payment").html(response);
        }
    });
}

function getSerializedData() {
    var items = [].concat.apply(
        [],
        [
            $("input[name=bundle_id]").serializeArray(),
            $("body .booking__service__content input[type=range]").serializeArray(),
            $("body .booking__service__content input:checked").serializeArray(),
            $("body .booking__service__content textarea").serializeArray(),
            $('body .booking__service__content select[name^="cleaning-"]').serializeArray(),
            $('body .booking__service__content select[name^="car-washing-"]').serializeArray(),
            $('body .booking__service__content input[name$="start_time"]').serializeArray(),
            $('body .booking__service__content input[name$="start_date"]').serializeArray(),
        ],
    );

    items.forEach(function (item) {
        try {
            var $input = $("[name=" + item.name + "]");
            item.type = $input[0]?.tagName.toLowerCase();
            if (item.type === "select" && Number(item.value) > 0) {
                // item.name = item.name.replace(/-\d+$/, "-" + item.value);
            }
            item.field_name = item.field_name || item.name;
            Object.defineProperty(
                item,
                "field_name",
                Object.getOwnPropertyDescriptor(item, "name"),
            );
            delete item.name;
        } catch (e) {}
    });

    return items;
}
