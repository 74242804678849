export function setupTimeSelector() {
    for (const elem of document.querySelectorAll("*[form-data-discount-percentage] + label span")) {
        const discountAmount = elem.parentElement.parentElement
            .querySelector("*[form-data-discount-percentage]")
            .getAttribute("form-data-discount-percentage");
        if (discountAmount == 0) {
            elem.textContent = "";
        } else {
            elem.textContent = elem.textContent.replace(/^(.*)%/, discountAmount + "%");
        }
    }
}
