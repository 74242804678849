export function setupBundlePopup() {
    $("a[href='#bundles_booknow_popup']").on("click", (e) => {
        const bundle = JSON.parse(e.target.getAttribute("data-bundle"));
        for (const [key, value] of Object.entries(bundle)) {
            const labelElem = $(`*[data-bundle-property=${key}]`).get(0);
            if (labelElem) {
                labelElem[labelElem.tagName.toLowerCase() !== "input" ? "textContent" : "value"] = value;
            }
        }
    });
}
